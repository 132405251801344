import type { Effect } from '@/models/connect';
import { AccountService, handleError } from '@propify-tenant-client/services';
import type { Reducer } from 'redux';

export interface SmsVerificationModelState {
  codeRequested?: boolean;
  tokenError?: boolean;
}

interface ModelType {
  namespace: string;
  state: SmsVerificationModelState;
  effects: {
    requestSms: Effect;
    submitSms: Effect;
  };
  reducers: {
    updateStatus: Reducer<SmsVerificationModelState>;
    updateTokenError: Reducer<SmsVerificationModelState>;
  };
}

const Model: ModelType = {
  namespace: 'verification',

  state: {
    codeRequested: false,
    tokenError: false,
  },

  effects: {
    *submitSms({ payload }, { call, put }) {
      try {
        yield call(AccountService.submitSms, payload);
        yield put({ type: 'account/me' });
      } catch (error: any) {
        handleError(error, { displayToast: false });
        error.handled = true;
        yield put({ type: 'updateTokenError', payload: true });
      }
    },
    *requestSms({ payload }, { call, put }) {
      yield call(AccountService.requestSms, payload);
      yield put({ type: 'updateStatus', payload: true });
    },
  },

  reducers: {
    updateStatus(state, { payload }) {
      return {
        ...state,
        codeRequested: payload,
      };
    },
    updateTokenError(state, { payload }) {
      return {
        ...state,
        tokenError: payload,
      };
    },
  },
};

export default Model;
