import type { Effect } from '@/models/connect';
import { setAuthority, setAuthorityToken } from '@/utils/authority';
import { LoginStatus } from '@propify-tenant-client/account';
import type { RentedUnit } from '@propify-tenant-client/services';
import { handleError, login, UnitService } from '@propify-tenant-client/services';
import type { Reducer } from 'redux';

export interface StateType {
  status?: LoginStatus;
}

interface LoginModelType {
  namespace: string;
  state: StateType;
  effects: {
    login: Effect;
    loginSuccess: Effect;
  };
  reducers: {
    changeLoginStatus: Reducer<StateType>;
    clearLoginError: Reducer<StateType>;
  };
}

const Model: LoginModelType = {
  namespace: 'login',

  state: {
    status: undefined,
  },

  effects: {
    *login({ payload }, { call, put }) {
      let response: any = null;
      try {
        yield put({
          type: 'clearLoginError',
        });

        response = yield call(login, payload);

        yield put({
          type: 'changeLoginStatus',
          payload: response,
        });

        const rentedUnit = (yield call(UnitService.getRentedUnit)) as RentedUnit[];
        yield put({
          type: 'account/saveRentedUnit',
          payload: rentedUnit,
        });
      } catch (error: any) {
        handleError(error, { displayToast: false });
        error.handled = true;

        const errorStatus = {
          status:
            error.response.status === 401
              ? LoginStatus.InvalidCredentialsError
              : LoginStatus.UnknownError,
        };

        yield put({
          type: 'changeLoginStatus',
          payload: errorStatus,
        });
      }

      // Login successfully
      if (response?.auth) {
        yield put({
          type: 'login/loginSuccess',
          payload: response!.login,
        });
      }
    },

    *loginSuccess({ payload }, { put }) {
      yield put({
        type: 'account/saveLoginState',
        payload,
      });
    },
  },

  reducers: {
    changeLoginStatus(state, { payload }) {
      if (payload.auth) {
        setAuthority('user');
        setAuthorityToken(payload.auth);

        // eslint-disable-next-line no-param-reassign
        payload.status = LoginStatus.Ok;
      } else {
        setAuthority('guest');
      }

      return {
        ...state,
        status: payload.status,
      };
    },
    clearLoginError(state) {
      return {
        ...state,
        status: undefined,
      };
    },
  },
};

export default Model;
