import type { Effect } from '@/models/connect';
import { AccountService, handleError } from '@propify-tenant-client/services';
import type { Reducer } from 'redux';

export interface EmailVerificationModelState {
  codeRequested?: boolean;
  tokenError?: boolean;
}

interface EmailModelType {
  namespace: string;
  state: EmailVerificationModelState;
  effects: {
    requestEmail: Effect;
    submitToken: Effect;
  };
  reducers: {
    updateEmailVerificationStatus: Reducer<EmailVerificationModelState>;
    updateTokenError: Reducer<EmailVerificationModelState>;
  };
}

const EmailVerificationModel: EmailModelType = {
  namespace: 'emailVerification',

  state: {
    codeRequested: false,
    tokenError: false,
  },

  effects: {
    *submitToken({ payload }, { call, put }) {
      try {
        yield call(AccountService.validateEmail, payload);
        yield put({ type: 'account/me' });
      } catch (error: any) {
        handleError(error, { displayToast: false });
        error.handled = true;
        yield put({ type: 'updateTokenError', payload: true });
      }
    },
    *requestEmail({ payload }, { call, put }) {
      yield call(AccountService.requestEmail, payload);
      yield put({ type: 'updateEmailVerificationStatus', payload: true });
    },
  },

  reducers: {
    updateEmailVerificationStatus(state, { payload }) {
      return {
        ...state,
        codeRequested: payload,
      };
    },
    updateTokenError(state, { payload }) {
      return {
        ...state,
        tokenError: payload,
      };
    },
  },
};

export default EmailVerificationModel;
